import * as React from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";

const NotFoundPage = () => {
  return (
    <>
      <Header />
      <main className="contact">
        <h1>404</h1>
        <div className="cloak__wrapper">
          <div className="cloak__container">
            <div className="cloak"></div>
          </div>
        </div>
        <div className="info">
          <h2>We can't find that page</h2>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default NotFoundPage;
